import React, {useEffect, useState} from 'react';
import {makeStyles} from "@mui/styles";
import {Bar, BarChart, CartesianGrid, Label, Legend, Tooltip, XAxis, YAxis} from "recharts";
import {Box, Card, List, ListItemText, Paper} from "@mui/material";
import {allOutletLoose, deviceOutletLooseRank, outlineStat, versionPie} from "../request/StatRequest";
import SelectBox from "../Components/SelectBox";
import {useSnackbar} from "notistack";
import {listProducts} from "../request/ProductRequest";


const useStyles = makeStyles({
    table: {
        marginTop: 20
    }
})


export default function Dashboard() {


    const [productTypeList, setProductTypeList] = useState([]);

    const [selectedAppId, setSelectedAppId] = useState(0);
    const alert = (message) => {
        enqueueSnackbar(message, {
            persist: false,
            variant: 'error',
        });
    };

    useEffect(() => {
        listProducts().then(data => setProductTypeList(data.data
        )).catch(data => {
            alert(data.message)
        })
    }, [])

    const [versionPieList, setVersionPieList] = useState([])
    const [outletLooseRate, setOutletLooseRate] = useState(0)
    const [outletLooseRank, setOutletLooseRank] = useState([])
    const [onlineStat, setOlineState] = useState({})


    const {enqueueSnackbar, closeSnackbar} = useSnackbar();


    const versionPieSearch = (appId) => {
        versionPie({
            status: 1,
            appId: appId
        }).then(data => {
            setVersionPieList(data.data)
        }).catch(data => {
            console.log("爬出的错误是:", data)
            alert("需要登录")
        })
    }

    useEffect(() => {
        versionPieSearch('');
        // allOutletLoose().then(r => {
        //     setOutletLooseRate(r.data)
        // }).catch(r => console.log(r))
        // deviceOutletLooseRank().then(r => {
        //     console.log("排行榜：", r.data)
        //     setOutletLooseRank(r.data)
        // }).catch(r => console.log(r))
        // outlineStat().then(r => {
        //     setOlineState(r.data)
        // }).catch(r => console.log(r))
    }, [])


    return (
        <React.Fragment>
            <Box className={'flex gap-4'}>
                <Card variant={'outlined'} className={'p-4'}>
                    <text className={'font-bold text-lg'}>
                        昨日插口松动率
                    </text>
                    <div className={'text-right mt-3'}>
                        <text className={'font-bold text-3xl'}>
                            {Math.floor(outletLooseRate * 100)}%
                        </text>
                    </div>
                </Card>

                <Card variant={'outlined'} className={'p-4'}>
                    <text className={'font-bold text-lg'}>
                        离在线(离线/总数)
                    </text>
                    <div className={'text-right mt-3'}>
                        <text className={'font-bold text-3xl text-red-600'}>
                            {onlineStat.outline}
                        </text>
                        <text className={'font-bold text-3xl'}>
                            /{onlineStat.total}
                        </text>

                    </div>
                </Card>
            </Box>

            <Box className={'flex gap-4'}>
            <Box className={'mt-10'}>
                <Paper variant={'outlined'}>
                    <Box className={'p-4'}>
                        <SelectBox selectList={productTypeList} nameTag={"productName"} valueTag={"appId"} isSearch
                                   handleChange={(value) => {
                                       setSelectedAppId(value)
                                       console.log("appId: ", value)
                                       versionPieSearch(value)
                                   }}/>

                    </Box>
                    <BarChart
                        width={800}
                        height={300}
                        data={versionPieList}
                        margin={{
                            top: 5,
                            bottom: 15,
                        }}
                        barSize={20}
                    >
                        <XAxis dataKey="name" scale="point" padding={{left: 10, right: 10}}>
                            <Label value="版本数量表" offset={-4} position="insideBottom"/>
                        </XAxis>
                        <YAxis/>
                        <Tooltip/>
                        <Legend verticalAlign={"top"} height={36}/>
                        <CartesianGrid strokeDasharray="3 3"/>
                        <Bar dataKey="count" fill="#8884d8" background={{fill: '#eee'}}>
                        </Bar>
                    </BarChart>
                </Paper>
            </Box>
            <Box className={'mt-10'}>
                <Paper variant={"outlined"} className={'p-5'}>
                    <text className={'font-bold text-lg'}>昨日插口松动排行(从高到低)</text>
                    <List>
                        {
                            outletLooseRank.map(it =>
                                <ListItemText primary={it.deviceSn + ":" + it.deviceName}/>
                            )
                        }
                    </List>
                </Paper>
            </Box>
            </Box>
        </React.Fragment>
    );
}
